import { Box, Button, Link, Modal } from '@amzn/awsui-components-react';
import { useIntl, FormattedMessage } from 'react-intl';

import { messages } from './InfoModal.messages';

const InfoModal = ({ openInfoModal, setOpenInfoModal, requiresSubProvider = false }) => {
    const { formatMessage } = useIntl();

    const clearModal = () => setOpenInfoModal(false);

    if (!openInfoModal) {
        return null;
    }

    return (
        <Modal
            visible={openInfoModal}
            onDismiss={clearModal}
            closeAriaLabel={formatMessage(messages.closeModalButtonLabel)}
            header={formatMessage(messages.studentCodeTitle)}
            footer={
                <div>
                    <Link external href='https://aws.gilmoreglobal.com/login'>
                        {formatMessage(messages.gilmoreStorefrontLink)}
                    </Link>
                    <Box float='right'>
                        <Button onClick={clearModal} variant='normal'>
                            {formatMessage(messages.closeModalButtonText)}
                        </Button>
                    </Box>
                </div>
            }
        >
            <p>
                <b>{formatMessage(messages.studentCodePartnersOnly)}</b>
            </p>
            <p>
                {formatMessage(
                    requiresSubProvider
                        ? messages.studentCodeSubProviderEKits
                        : messages.studentCodeEKits,
                )}
            </p>
            <FormattedMessage
                tagName='p'
                {...(requiresSubProvider
                    ? messages.studentCodeUnusedSubProviderEKits
                    : messages.studentCodeUnusedEKits)}
            />
        </Modal>
    );
};

export default InfoModal;

import { Header, Container, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { CopyButton } from '@/components/copyButton/CopyButton';

import messages from './SurveyLinkWidget.messages';
import { truncateText } from './SurveyLinkWidget.utils';

const SurveyLinkWidget = ({ surveyURL }) => {
    const { formatMessage } = useIntl();
    const maxTextLength = 70;

    return (
        <Container
            header={<Header variant='h2'>{formatMessage(messages.surveyLinkHeader)}</Header>}
        >
            <SpaceBetween direction='horizontal' alignItems='start' data-styles='space-between'>
                <Link href={surveyURL}>{truncateText(surveyURL, maxTextLength)}</Link>
                <CopyButton label={messages.copyLinkLabel}>{surveyURL}</CopyButton>
            </SpaceBetween>
        </Container>
    );
};

export default SurveyLinkWidget;

import Select from '@amzn/awsui-components-react/polaris/select';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';

import { messages, timezones } from './TimeZoneSelect.messages';
const timezoneKeys = Object.keys(timezones);

const formatGrimsbyTimeZone = (timezone) => {
    const timeZoneOffset = moment.tz(timezone).format('Z');
    const formattedOffset = timeZoneOffset.replace(/^([+-])0/, '$1'); // removes leading 0 after +/-
    return `(GMT${formattedOffset}) ${timezone}`;
};

const TimeZoneSelect = ({ selected, onChange, invalid, disabled = false, isGrimsbyClass }) => {
    const { formatMessage } = useIntl();

    const timeZoneOptions = timezoneKeys
        .map((key) => {
            const label = timezones[key];
            const offset = moment.tz(key)._offset / 60;

            return {
                name: key,
                label: formatMessage(label),
                offset: offset,
            };
        })
        .sort((a, b) => {
            return a.offset - b.offset;
        })
        .map(({ offset, name, label }) => {
            const min = offset * 60;
            const hour = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : min % 60);
            return {
                value: name,
                label: `${formatMessage(messages.timeZoneLabel, {
                    direction: hour.includes('-') ? '' : '+',
                    hour: hour,
                })} ${label}`,
            };
        });

    const getOptionForTimezone = (timezone) => {
        if (isGrimsbyClass) {
            return {
                value: timezone,
                label: formatGrimsbyTimeZone(timezone),
            };
        }
        return timeZoneOptions.find((o) => o.value === timezone);
    };

    return (
        <Select
            data-testid='timezone-select'
            ariaRequired
            filteringType='auto'
            invalid={Boolean(invalid)}
            options={timeZoneOptions}
            selectedOption={getOptionForTimezone(selected)}
            disabled={disabled}
            onChange={onChange}
            placeholder={formatMessage(messages.timeZonePlaceholder)}
            selectedLabel={formatMessage(messages.selectedLabel)}
        />
    );
};

export default TimeZoneSelect;

import { Button } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { useFlags } from '@/utils/flags';

import messages from './CancelClass.messages';

import './CancelClass.scss';

const CancelClass = ({ isCancellable, openConfirmationDialog }) => {
    const { formatMessage } = useIntl();

    const onClick = () => openConfirmationDialog(true);

    const flags = useFlags();

    if (!(flags.cancelClassroom === true && isCancellable !== null)) return null;

    return (
        <>
            <Button
                variant='normal'
                className='class-detail__action-btn'
                disabled={isCancellable !== true}
                onClick={onClick}
                data-testid='cancel-class-button'
            >
                {formatMessage(messages.cancelClassButtonText)}
            </Button>
        </>
    );
};

export default CancelClass;

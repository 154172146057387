import { isPreloading } from '@/components/ingressTable/IngressTable.utils';
import type { Training } from '@/data/trainings.types';

interface ParsedTrainings {
    provisioning: Training[];
    unassigned: Training[];
    assigned: Training[];
    ended: Training[];
    failed: Training[];
    unknown: Training[];
}

export const parseStudentTrainingsData = (trainings: Training[]): ParsedTrainings => {
    return trainings
        .filter((training) => training.state !== 'NO_LAB')
        .reduce(
            (acc: ParsedTrainings, training) => {
                const { labStatus } = training.metaData ?? {};
                const { provisioning, unassigned, assigned, ended, failed, unknown } = acc;
                if (training.fulfillmentStatus === 'error') {
                    failed.push(training);
                } else if (isPreloading(labStatus)) {
                    provisioning.push(training);
                } else if (labStatus === 'SESSION_ENDED') {
                    ended.push(training);
                } else if (labStatus === 'CONSOLE_AVAILABLE') {
                    // NOTE: maybe use isPooledLab function from IngressTable.utils?
                    if (!training.email) {
                        unassigned.push(training);
                    } else {
                        assigned.push(training);
                    }
                } else {
                    unknown.push(training);
                }
                return { provisioning, unassigned, assigned, ended, failed, unknown };
            },
            {
                provisioning: [],
                unassigned: [],
                assigned: [],
                ended: [],
                failed: [],
                unknown: [],
            },
        );
};

import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    labPoolDetailsTitle: {
        id: 'labPoolDetails.title',
        defaultMessage: 'Lab pool',
    },
    labPoolDetailsProvisioning: {
        id: 'labPoolDetails.provisioning',
        defaultMessage: 'Provisioning',
    },
    labPoolDetailsUnassigned: {
        id: 'labPoolDetails.unassigned',
        defaultMessage: 'Unassigned',
    },
    labPoolDetailsAssigned: {
        id: 'labPoolDetails.assigned',
        defaultMessage: 'Assigned',
    },
    labPoolDetailsEnded: {
        id: 'labPoolDetails.ended',
        defaultMessage: 'Ended',
    },
    labPoolDetailsFailure: {
        id: 'labPoolDetails.failure',
        defaultMessage: 'Preload failure',
    },
    labPoolDetailsUnknown: {
        id: 'labPoolDetails.unknown',
        defaultMessage: 'Unknown',
    },
});

export default messages;

import { Button, Header, Pagination, Table, Box } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { SelectReportModal } from '@/components';
import { useProvider } from '@/data/ProviderContext';
import { listProviderReports } from '@/modules';

import messages from './ReportTable.messages';
import {
    downloadReport,
    buildColumnDefinitions,
    getTotalPages,
    paginatedReports,
    paginationLabels,
    listReportsQueryOptions,
    getReportIdFromSelectedItems,
} from './ReportTable.utils';

const ReportTable = () => {
    const { formatMessage } = useIntl();
    const providerArn = useProvider()?.arn;
    const [selectedItems, setSelectedItems] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoadingReport, setIsLoadingReport] = useState(false);

    const { data, isLoading } = useQuery(
        ['listProviderReports', providerArn],
        () => (providerArn ? listProviderReports(providerArn) : []),
        listReportsQueryOptions,
    );

    const clearModal = () => setIsModalVisible(false);
    const onDownloadBtnPress = async () => {
        if (selectedItems.length === 0) {
            setIsModalVisible(true);
            return;
        }

        setIsLoadingReport(true);
        const reportId = getReportIdFromSelectedItems(selectedItems);
        await downloadReport(reportId, providerArn);
        setIsLoadingReport(false);
    };

    return (
        <div>
            <Table
                header={
                    <Header
                        actions={
                            <Button
                                variant='primary'
                                loading={isLoadingReport}
                                onClick={onDownloadBtnPress}
                                disabled={!isLoading && (!Array.isArray(data) || data.length === 0)}
                                data-testid='download-report-button'
                            >
                                {formatMessage(messages.downloadButtonText)}
                            </Button>
                        }
                    >
                        {formatMessage(messages.tableHeader)}
                    </Header>
                }
                pagination={
                    <Pagination
                        pagesCount={getTotalPages(data)}
                        currentPageIndex={currentPage}
                        disabled={isLoading}
                        onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
                        ariaLabels={paginationLabels(formatMessage)}
                    />
                }
                columnDefinitions={buildColumnDefinitions(formatMessage)}
                items={paginatedReports(data, currentPage)}
                resizableColumns={true}
                empty={
                    <Box textAlign='center'>
                        <Box padding={{ top: 's' }} margin={{ bottom: 'xs' }}>
                            <b>{formatMessage(messages.tableEmpty)}</b>
                        </Box>
                    </Box>
                }
                loading={isLoading}
                selectionType='single'
                selectedItems={selectedItems}
                onSelectionChange={({ detail }) => {
                    setSelectedItems(detail.selectedItems);
                }}
            />
            <SelectReportModal isVisible={isModalVisible} clearModal={clearModal} />
        </div>
    );
};

export default ReportTable;

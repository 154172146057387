import { Modal, Box, SpaceBetween, Button, FormField, Input } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useClassroomData } from '@/data/useClassroomData';
import { useFlags } from '@/utils';
import { useDecodedParams } from '@/utils/paramHooks';
import { usePooledLabs } from '@/utils/pooledLabs';

import messages from './index.messages';

import type { InputProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import type { MessageDescriptor } from 'react-intl';

const {
    provisionPoolModalTitle,
    provisionPoolModalCreateInputLabel,
    provisionPoolModalCreateInputDescription,
    provisionPoolModalEditInputLabel,
    provisionPoolModalEditInputDescription,
    provisionPoolModalMessage,
    provisionPoolModalEdit,
    provisionPoolModalCancel,
    provisionPoolModalSubmit,
} = messages;

interface ProvisionPoolModalProps {
    buttonText: MessageDescriptor;
    inputLabel: MessageDescriptor;
    inputDescription: MessageDescriptor;
    selectedRegion: string;
}

const MAX_MULTIPLIER = 3;
const PENDING_AMOUNT = '...';

const ProvisionPoolModal = ({
    buttonText,
    inputLabel,
    inputDescription,
    selectedRegion,
}: ProvisionPoolModalProps) => {
    const { formatMessage } = useIntl();
    const { classroomId } = useDecodedParams();
    const { classData } = useClassroomData(classroomId);
    const { classCapacity = 0 } = classData?.classroom ?? {};
    const maxLabs = classCapacity * MAX_MULTIPLIER;

    const flags = useFlags();
    const [isVisible, isVisibleSet] = useState(false);

    const [inputValue, inputValueSet] = useState(PENDING_AMOUNT);

    const { request } = usePooledLabs();

    const onChange = (e: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
        inputValueSet(Math.min(maxLabs, Math.max(+e.detail.value, 1)).toString());
    };
    const onSubmit = async () => {
        await request({ targetCount: +inputValue });
        isVisibleSet(false);
    };

    useEffect(() => {
        if (classCapacity !== 0 && inputValue === PENDING_AMOUNT) {
            inputValueSet(classCapacity);
        }
    }, [classCapacity, inputValue]);

    if (!flags.pooledLabs || !classCapacity) return null;

    return (
        <>
            <Button onClick={() => isVisibleSet(true)}>{formatMessage(buttonText)}</Button>
            <Modal
                onDismiss={() => isVisibleSet(false)}
                visible={isVisible}
                data-testid={`provision-pool-modal--${isVisible ? 'visible' : 'hidden'}`}
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link'>
                                {formatMessage(provisionPoolModalCancel)}
                            </Button>
                            <Button variant='primary' onClick={onSubmit}>
                                {formatMessage(provisionPoolModalSubmit)}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={formatMessage(provisionPoolModalTitle)}
            >
                <SpaceBetween direction='vertical' size='m'>
                    <FormField
                        label={formatMessage(inputLabel)}
                        description={formatMessage(inputDescription)}
                    >
                        <Input
                            type='number'
                            value={inputValue}
                            onChange={onChange}
                            inputMode='numeric'
                            data-testid='pooled-labs-input'
                            step={1}
                        />
                    </FormField>
                    <Box variant='p'>
                        {formatMessage(provisionPoolModalMessage, { region: selectedRegion })}
                    </Box>
                </SpaceBetween>
            </Modal>
        </>
    );
};

export const CreateProvisionPoolModal = (
    props: Omit<ProvisionPoolModalProps, 'buttonText' | 'inputLabel' | 'inputDescription'>,
) => (
    <ProvisionPoolModal
        buttonText={provisionPoolModalTitle}
        inputLabel={provisionPoolModalCreateInputLabel}
        inputDescription={provisionPoolModalCreateInputDescription}
        {...props}
    />
);

export const EditProvisionPoolModal = (
    props: Omit<ProvisionPoolModalProps, 'buttonText' | 'inputLabel' | 'inputDescription'>,
) => (
    <ProvisionPoolModal
        buttonText={provisionPoolModalEdit}
        inputLabel={provisionPoolModalEditInputLabel}
        inputDescription={provisionPoolModalEditInputDescription}
        {...props}
    />
);

import { useParams } from 'react-router-dom';

import { useClassroomData } from '@/data/useClassroomData';

interface AppParams {
    classroomId?: string;
    labNumber?: string;
}

export const useDecodedParams = () => {
    const { classroomId: encodedClassroomId, labNumber } = useParams<AppParams>();
    return {
        classroomId: encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined,
        labNumber: labNumber ? parseInt(labNumber) : undefined,
    };
};

export const useParseUrlParamsForLabData = () => {
    const { classroomId, labNumber } = useDecodedParams();
    const {
        classData: { content },
    } = useClassroomData(classroomId);

    return content?.[labNumber! - 1] ?? {};
};

import { ButtonDropdown } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { useClassroomData } from '@/data/useClassroomData';
import { getStudentURL, openTab } from '@/utils/helpers';
import { paths } from '@/utils/paths';

import messages from './index.messages';

const { dayOf, classDetails, manageLabs, studentView, externalLabel } = messages;

export const findCurrentPage = (path, classroomId, labNumber) => {
    switch (path) {
        case paths.classPage(classroomId):
            return dayOf;
        case paths.classDetailPage(classroomId):
            return classDetails;
        case paths.labPage(classroomId, labNumber):
            return manageLabs;
        default:
            return null;
    }
};

const ManageClassDropdown = () => {
    const { formatMessage } = useIntl();
    const { classroomId: encodedClassroomId, labNumber } = useParams();
    const decodedClassroomId = decodeURIComponent(encodedClassroomId);
    const { pathname } = useLocation();
    const { hasNoLabs, hasNoJams } = useClassroomData(decodedClassroomId);
    const history = useHistory();

    const onClick = (e) => {
        e.preventDefault();
        const { external, href } = e.detail;
        if (external) {
            openTab(href);
        } else {
            history.push(href);
        }
    };

    const currentPage = findCurrentPage(pathname, encodedClassroomId, labNumber);
    if (!currentPage) return null;

    const dropdownItems = [
        {
            id: 'day-of',
            href: paths.classPage(encodedClassroomId),
            text: formatMessage(dayOf),
            disabled: (hasNoJams && hasNoLabs) || currentPage === dayOf,
        },
        {
            id: 'details',
            href: paths.classDetailPage(encodedClassroomId),
            text: formatMessage(classDetails),
            disabled: currentPage === classDetails,
        },
        {
            id: 'labs',
            href: paths.labPage(encodedClassroomId, labNumber || '1'),
            text: formatMessage(manageLabs),
            disabled: hasNoLabs || currentPage === manageLabs,
        },
        {
            id: 'student-view',
            href: getStudentURL(encodedClassroomId),
            text: formatMessage(studentView),
            external: true,
            externalIconAriaLabel: formatMessage(externalLabel),
        },
    ];

    return (
        <ButtonDropdown
            data-testid='manage-class-dropdown'
            items={dropdownItems}
            onItemClick={onClick}
        >
            {formatMessage(currentPage)}
        </ButtonDropdown>
    );
};

export default ManageClassDropdown;

import { createContext, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import shortUUID from 'short-uuid';

import {
    isAllConsoleReady,
    isPreloadFailed,
    isExceedingLabLimit,
} from '@/components/ingressTable/IngressTable.utils';
import type { Training } from '@/data/trainings.types';
import { preloadLab } from '@/modules/api';
import { useAppNotifications } from '@/utils/appNotifications';

import messages from './messages';

import type { PropsWithChildren } from 'react';

const pooledCountFilterRegex = /(unassigned|unregistered|pooled)-/i;

interface Batch {
    batchId: string;
    status: 'in-progress' | 'closed';
    data: any;
}

interface TargetedRequestPooledLabsParams {
    userKeys: string[];
    targetCount?: never;
}

interface GenericRequestPooledLabsParams {
    userKeys?: never;
    targetCount: number;
}

interface isBatchCheckerInput {
    batchId: string;
    labId: string;
    trainings: Training[];
}

export const isBatchFailed = ({ batchId, trainings, labId }: isBatchCheckerInput) => {
    return trainings.some(
        (tr) => tr.arn === labId && tr.requestClientToken?.includes(batchId) && isPreloadFailed(tr),
    );
};

export const isBatchExceedLabLimit = ({ batchId, trainings, labId }: isBatchCheckerInput) => {
    return trainings.some(
        (tr) =>
            tr.arn === labId &&
            tr.requestClientToken?.includes(batchId) &&
            isExceedingLabLimit(tr.fulfillmentError?.name),
    );
};

export const isBatchReady = ({ batchId, trainings, labId }: isBatchCheckerInput) => {
    return isAllConsoleReady(trainings, labId, batchId);
};

interface PooledLabsState {
    request: (
        params: TargetedRequestPooledLabsParams | GenericRequestPooledLabsParams,
    ) => Promise<void>;
}
export const PooledLabsCtx = createContext<PooledLabsState>({ request: () => Promise.resolve() });

export interface PooledLabsProviderProps {
    langLocale: string;
    classroomId: string;
    labId: string;
    studentTrainings: Training[];
    batchRegion?: string;
}
export const PooledLabsProvider = ({
    children,
    langLocale,
    classroomId,
    labId,
    batchRegion,
    studentTrainings,
}: PropsWithChildren<PooledLabsProviderProps>) => {
    const [batches, batchesSet] = useState<{ [labId: string]: Batch[] }>({});
    const [isRequesting, isRequestingSet] = useState(false);
    const { addNotification } = useAppNotifications();
    const { formatMessage } = useIntl();

    const batchesByLab = batches[labId] ?? [];

    const request = async ({
        userKeys,
        targetCount,
    }: TargetedRequestPooledLabsParams | GenericRequestPooledLabsParams) => {
        if (isRequesting) return;

        isRequestingSet(true);
        const pooledLabCount =
            targetCount ?? userKeys.filter((u) => pooledCountFilterRegex.test(u)).length;
        const filteredUserKeys = userKeys?.filter((u) => !pooledCountFilterRegex.test(u));
        try {
            const { createStudentTrainings: data } = await preloadLab({
                classroomId,
                blueprintArn: labId,
                clientToken: shortUUID.generate(),
                preferredLangLocale: langLocale,
                ...(batchRegion ? { targetRegions: [batchRegion] } : undefined),
                ...(filteredUserKeys?.length ? { userKeys: filteredUserKeys } : {}),
                ...(pooledLabCount && pooledLabCount > 0 ? { pooledLabCount } : {}),
            });
            data.batchId = data.batchId ?? data.labPoolBatchInfo?.batchId;
            batchesSet((prev) => ({ ...prev, [labId]: [...(prev[labId] ?? []), data] }));
            addNotification({
                type: 'info',
                content: formatMessage(messages.preloadInProgress),
            });
        } catch (error) {
            console.error(error);
            addNotification({
                type: 'error',
                content: formatMessage(messages.preloadFail),
            });
        } finally {
            isRequestingSet(false);
        }
    };

    useEffect(() => {
        if (!batchesByLab.length) return;

        const remainingBatches: Batch[] = [];
        batchesByLab.forEach((batch) => {
            const { batchId } = batch;
            const params = { trainings: studentTrainings, labId, batchId };

            if (isBatchReady(params)) {
                addNotification({
                    type: 'success',
                    content: formatMessage(messages.preloadSuccess),
                });
            } else if (isBatchFailed(params)) {
                addNotification({
                    type: 'error',
                    content: formatMessage(messages.preloadFail),
                });
            } else if (isBatchExceedLabLimit(params)) {
                addNotification({
                    type: 'warning',
                    content: formatMessage(messages.preloadExceedLabLimit),
                });
            } else {
                remainingBatches.push(batch);
            }
        });
        batchesSet((prev) => ({ ...prev, [labId]: remainingBatches }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentTrainings, labId]);

    return <PooledLabsCtx.Provider value={{ request }}>{children}</PooledLabsCtx.Provider>;
};

export const usePooledLabs = () => useContext(PooledLabsCtx);

import { defineMessages } from 'react-intl.macro';

const ingressTableMessages = defineMessages({
    tableEmpty: {
        id: 'ingress.table.empty',
        defaultMessage: 'No trainings to display.',
    },
    tableHeaderStudent: {
        id: 'ingress.header.student',
        defaultMessage: 'Student',
    },
    tableHeaderLabStatus: {
        id: 'ingress.header.labStatus',
        defaultMessage: 'Lab Status',
    },
    tableHeaderRegion: {
        id: 'ingress.header.region',
        defaultMessage: 'Region',
    },
    tableHeaderControls: {
        id: 'ingress.header.controls',
        defaultMessage: 'Access Controls',
    },
    errorStatusLabel: {
        id: 'ingress.status.error',
        defaultMessage: 'Error',
    },
    successStatusLabel: {
        id: 'ingress.status.success',
        defaultMessage: 'Success',
    },
    infoStatusLabel: {
        id: 'ingress.status.info',
        defaultMessage: 'Info',
    },
    preloadClassNotStarted: {
        id: 'ingress.preload.classNotStarted',
        defaultMessage: 'Class has not started. Pre-loading labs is not available',
    },
    ingressControl: {
        id: 'ingress.button.ingress',
        defaultMessage: 'Enter Console',
    },
    cannotIngress: {
        id: 'ingress.modal.cannotIngress',
        defaultMessage: 'Cannot Ingress',
    },
    close: {
        id: 'ingress.modal.studentLabEnded.close',
        defaultMessage: 'Close',
    },
    closeModalLabelText: {
        id: 'ingress.modal.closeLabelText',
        defaultMessage: 'Close dialog',
    },
    studentLabEnded: {
        id: 'ingress.modal.studentLabEnded',
        defaultMessage: "The student's lab has ended",
    },
    tableExceedLabLimit: {
        id: 'ingress.table.exceededLabLimit',
        defaultMessage: 'Exceeded the lab limit',
    },
    ingressingControl: {
        id: 'ingress.button.ingressing',
        defaultMessage: 'Ingressing',
    },
    labTitle: {
        id: 'ingress.lab.title',
        defaultMessage: 'Lab',
    },
    tableStatusAriaLabel: {
        id: 'ingress.table.aria.status',
        defaultMessage: 'Lab {status} for {student}',
    },
    tableReadyLabStatus: {
        id: 'ingress.table.labReady',
        defaultMessage: 'Ready',
    },
    tablePreloadingLabStatus: {
        id: 'ingress.table.labPreloading',
        defaultMessage: 'Preloading in progress',
    },
    tablePreloadedLabStatus: {
        id: 'ingress.table.labPreloaded',
        defaultMessage: 'Preloaded',
    },
    tableEndedLabStatus: {
        id: 'ingress.table.labEnded',
        defaultMessage: 'Ended',
    },
    tableFailedLabStatus: {
        id: 'ingress.table.labFailed',
        defaultMessage: 'Preload failed',
    },
    tableRunningLabStatus: {
        id: 'ingress.table.labRunning',
        defaultMessage: 'Running',
    },
    tableExpiredLabStatus: {
        id: 'ingress.table.labExpired',
        defaultMessage: 'Expired',
    },
    ingressNoConsole: {
        id: 'ingress.button.noConsole',
        defaultMessage: 'None',
    },
    resetRegion: {
        id: 'ingress.header.resetRegion',
        defaultMessage:
            'Your previously selected region {selectedRegion} is not available for Lab {labNumber}. A supported region has been selected for you.',
    },
    getRegionFailure: {
        id: 'ingress.header.getRegionFailure',
        defaultMessage:
            'Unable to get previously selected region for Lab {labNumber}. A local supported region has been selected for you; you may use it to provision student labs.',
    },
    putRegionFailure: {
        id: 'ingress.header.putRegionFailure',
        defaultMessage:
            'Unable to save local region for Lab {labNumber}. You may still use it to provision student labs.',
    },
    itemsDisplayedLabel: {
        id: 'ingress.selection.itemsDisplayedLabel',
        defaultMessage: 'Displaying labs {firstIndex} to {lastIndex} of {totalItemsCount}',
    },
    labsSelectionLabel: {
        id: 'ingress.selection.labsSelectionLabel',
        defaultMessage: 'Labs selection',
    },
    allLabsSelectedLabelSingle: {
        id: 'ingress.selection.allLabsSelectedLabelSingle',
        defaultMessage: '{amount} lab selected',
    },
    allLabsSelectedLabelMulti: {
        id: 'ingress.selection.allLabsSelectedLabelMulti',
        defaultMessage: '{amount} labs selected',
    },
    itemSelectionLabel: {
        id: 'ingress.selection.itemSelectionLabel',
        defaultMessage: 'student {identifier}',
    },
});

export default ingressTableMessages;

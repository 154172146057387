import { Container, Header, Button } from '@amzn/awsui-components-react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { redirectToLoginPage } from '@/modules';
import { useUserInfo } from '@/utils';

import { getFormattedIdpName } from '../identityErrorPage/IdentityErrorPage.utils';

import messages from './SplitLoginPage.messages';

import '../identityErrorPage/IdentityErrorPage.scss';

const SplitLoginPage = ({ originalSignInMethodName }) => {
    const { formatMessage } = useIntl();
    const { email, public_provider_name } = useUserInfo();

    const currentSignInMethodMessage = getFormattedIdpName(public_provider_name, formatMessage);
    const originalSignInMethodMessage = getFormattedIdpName(
        originalSignInMethodName,
        formatMessage,
    );

    const handleRedirect = async () => {
        redirectToLoginPage(false, originalSignInMethodName);
    };

    return (
        <div className='full-page-modal'>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <div className='container-wrapper'>
                <Container header={<Header variant='h2'>{formatMessage(messages.title)}</Header>}>
                    {formatMessage(messages.mainMessage, {
                        currentSignInMethod: currentSignInMethodMessage,
                        originalSignInMethod: originalSignInMethodMessage,
                        userEmail: email,
                        bold: (str) => <b>{str}</b>,
                    })}
                    <div className='button-wrapper'>
                        <Button
                            fullWidth
                            variant='primary'
                            onClick={handleRedirect}
                            data-testid='button-split-login-redirect'
                        >
                            {formatMessage(messages.buttonRedirect, {
                                signInMethod: originalSignInMethodMessage,
                            })}
                        </Button>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default SplitLoginPage;

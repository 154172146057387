import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { EditProvisionPoolModal } from '@/components/pooledLabs/provisionPoolModal';
import type { Training } from '@/data/trainings.types';
import { sendClientLog } from '@/modules';
import { useFlags } from '@/utils';

import messages from './index.messages';
import { parseStudentTrainingsData } from './utils';

import './index.scss';

interface LabPoolDetailsProps {
    trainings: Training[];
    selectedRegion: string;
}

export const LabPoolDetails = ({ trainings = [], selectedRegion }: LabPoolDetailsProps) => {
    const flags = useFlags();
    const { formatMessage } = useIntl();

    const { provisioning, unassigned, assigned, ended, failed, unknown } =
        parseStudentTrainingsData(trainings);

    const poolData = [
        {
            label: formatMessage(messages.labPoolDetailsProvisioning),
            value: provisioning.length,
        },
        {
            label: formatMessage(messages.labPoolDetailsUnassigned),
            value: unassigned.length,
        },
        {
            label: formatMessage(messages.labPoolDetailsAssigned),
            value: assigned.length,
        },
        {
            label: formatMessage(messages.labPoolDetailsEnded),
            value: ended.length,
        },
        {
            label: formatMessage(messages.labPoolDetailsFailure),
            value: failed.length,
        },
    ];
    if (unknown.length) {
        poolData.push({
            label: formatMessage(messages.labPoolDetailsUnknown),
            value: unknown.length,
        });
    }

    useEffect(() => {
        if (!unknown.length) return;
        sendClientLog({
            type: 'PooledLabs',
            attributes: {
                unknownLabStatuses: unknown.map(({ metaData, bunsenLabStatus, state }) =>
                    JSON.stringify({ labStatus: metaData?.labStatus, bunsenLabStatus, state }),
                ),
                unknownLabStatusesCount: unknown.length,
            },
            metrics: { unhandledLabState: [1, 'Count'] },
        });
    }, [unknown]);

    if (!flags.pooledLabs || !trainings.length) return null;

    return (
        <Box>
            <SpaceBetween direction='horizontal' size='m' data-styles='space-between'>
                <Box variant='h3'>{formatMessage(messages.labPoolDetailsTitle)}</Box>
                <EditProvisionPoolModal selectedRegion={selectedRegion} />
            </SpaceBetween>
            <dl className='lab-pool-details__data'>
                <SpaceBetween direction='horizontal' size='xl'>
                    {poolData.map(({ label, value }) => (
                        <div key={label}>
                            <dt>{label}</dt>
                            <dd>{value}</dd>
                        </div>
                    ))}
                </SpaceBetween>
            </dl>
        </Box>
    );
};

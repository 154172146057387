import { Button, Box, Modal } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './SelectReportModal.messages';

const SelectReportModal = ({ isVisible, clearModal }) => {
    const { formatMessage } = useIntl();

    return (
        <Modal
            visible={isVisible}
            header={formatMessage(messages.modalHeader)}
            onDismiss={clearModal}
            closeAriaLabel={formatMessage(messages.dismissButtonLabel)}
            footer={
                <Box float='right'>
                    <Button onClick={clearModal} variant='primary'>
                        {formatMessage(messages.closeButtonText)}
                    </Button>
                </Box>
            }
        >
            {formatMessage(messages.modalContent)}
        </Modal>
    );
};

export default SelectReportModal;

import { defineMessages } from 'react-intl.macro';

const ingressTableMessages = defineMessages({
    preloadInProgress: {
        id: 'ingress.preload.inProgress',
        defaultMessage:
            'Pre-loading in progress. To view the current status of pre-loaded labs, click the "Refresh" button below.',
    },
    preloadSuccess: {
        id: 'ingress.preload.success',
        defaultMessage: 'Lab pre-load was successful.',
    },
    preloadFail: {
        id: 'ingress.preload.fail',
        defaultMessage:
            'A few labs failed to pre-load. Please try again by pressing the "Pre-load all" button.',
    },
    preloadExceedLabLimit: {
        id: 'ingress.preload.exceedLabLimit',
        defaultMessage:
            'Active lab number exceeded the maximum for some students. You can contact students to manage pre-loaded labs.',
    },
});

export default ingressTableMessages;
